#crmWebToEntityForm.zcwf_lblLeft {
    width:100%;
    box-sizing: border-box;
}
#crmWebToEntityForm.zcwf_lblLeft * {
    box-sizing: border-box;
}
#crmWebToEntityForm{text-align: left;}
#crmWebToEntityForm * {
    direction: ltr;
}
.zcwf_lblLeft .zcwf_title {
    word-wrap: break-word;
    padding: 0px 6px 10px;
    font-weight:bold }.zcwf_lblLeft.cpT_primaryBtn:hover{
    background: linear-gradient(#02acff 0,#006be4 100%) no-repeat padding-box !important;
    box-shadow: 0 -2px 0 0 #0159b9 inset !important;
    border: 0 !important;
    color: #fff !important;
    outline: 0 !important;
}

.zcwf_lblLeft .zcwf_col_fld input[type=text]:not(.phone), input[type=password], .zcwf_lblLeft .zcwf_col_fld textarea, .zcwf_lblLeft .zcwf_col_fld .phone-fld {
    @apply w-full border border-solid border-slate-400 rounded-md h-10 px-2 text-lg;
}
.zcwf_lblLeft .zcwf_col_fld input[type=text], input[type=password], .zcwf_lblLeft .zcwf_col_fld textarea{
    @apply outline-none;
}
.zcwf_lblLeft .phone-fld:focus-within, :is(.zcwf_lblLeft .zcwf_col_fld input[type=text]):not(.phone):focus-within{
    @apply border-blue-500 border;
}
.zcwf_lblLeft .zcwf_col_lab {
    @apply w-full font-medium text-lg text-slate-700 mb-2 !important;
}
.zcwf_lblLeft .zcwf_col_fld {
    @apply w-full relative;
}
.zcwf_lblLeft .zcwf_privacy{padding: 6px;}
.zcwf_lblLeft .wfrm_fld_dpNn{display: none;}
.dIB{display: inline-block;}
.zcwf_lblLeft .zcwf_col_fld_slt {
    width: 60%;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
    float: left;
    resize: vertical;
    padding: 2px 5px;
}
.zcwf_lblLeft .zcwf_row:after, .zcwf_lblLeft .zcwf_col_fld:after {
    content: '';
    display: table;
    clear: both;
}
.zcwf_lblLeft .zcwf_col_help {
    float: left;
    margin-left: 7px;
    font-size: 14px;
    max-width: 35%;
    word-break: break-word;
}
.zcwf_lblLeft .zcwf_help_icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: #fff;
    border: 1px solid #c0c6cc;
    color: #c1c1c1;
    text-align: center;
    font-size: 11px;
    line-height: 16px;
    font-weight: bold;
    border-radius: 50%;
}
.zcwf_lblLeft .formsubmit {
    @apply h-10 ml-0 outline-none text-white border-blue-600 bg-blue-600 cursor-pointer text-lg border-solid px-5 rounded-md;
}
.zcwf_lblLeft .zcwf_privacy_txt {
    width: 90%;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-family: Arial;
    display: inline-block;
    vertical-align: top;
    color: var(--baseColor);
    padding-top: 2px;
    margin-left: 6px;
}

.zcwf_lblLeft .zcwf_tooltip_over{
    position: relative;
}
.zcwf_lblLeft .zcwf_tooltip_ctn{
    position: absolute;
    background: #dedede;
    padding: 3px 6px;
    top: 3px;
    border-radius: 4px;word-break: break-word;
    min-width: 100px;
    max-width: 150px;
    color: var(--baseColor);
    z-index: 100;
}
.zcwf_lblLeft .zcwf_ckbox{
    float: left;
}
.zcwf_lblLeft .zcwf_file{
    width: 55%;
    box-sizing: border-box;
    float: left;
}
.cBoth:after{
    content:'';
    display: block;
    clear: both;
}
@media all and (max-width: 600px) {
    .zcwf_lblLeft .zcwf_col_lab, .zcwf_lblLeft .zcwf_col_fld {
        width: auto;
        float: none !important;
    }
    .zcwf_lblLeft .zcwf_col_help {width: 40%;}
}
.contact-us-form-modal{
    @apply top-0 pb-0 md:pb-6 md:top-25 !important;
}
.contact-us-form-modal .ant-modal-content{
    @apply p-8;
}
.contact-us-form-modal .ant-modal-content .ant-modal-close{
    @apply top-8 right-8;
}