@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
html {
    font-size: 14px;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif; */
    font-family:  "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --header-height: 5rem;
    --color-green-1: #217c58;
    --color-green-2: #20b486;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.header-height {
    height: var(--header-height);
}

.content-h,
.content-h-mt {
    height: calc(100dvh - var(--header-height));
}
.content-h-mt {
    margin-top: var(--header-height);
}
.min-content-h{
    min-height: calc(100dvh - var(--header-height));
}

.content-width {
    @apply max-w-screen-xl mx-4 md:mx-auto;
}

.bg-green-1 {
    background-color: var(--color-green-1);
}
.bg-green-2 {
    background-color: var(--color-green-2);
}
.bg-gradiant-1 {
    background-image: linear-gradient(to right, #4923b4, #34a853);
}

.text-green-2 {
    color: var(--color-green-2);
}


.course-card {
    @apply drop-shadow-lg shrink-0 hover:scale-105 origin-center transition-transform;
}
.course-card .ant-card-meta-title{
    @apply text-wrap;
}


.ant-carousel .slick-dots{
    @apply items-center;
}


.ant-carousel .slick-dots li{
    @apply rounded-full overflow-hidden;
}
.ant-carousel .slick-dots li,.ant-carousel .slick-dots li button{
    @apply w-2 h-2;
}
.ant-carousel .slick-dots li.slick-active,.ant-carousel .slick-dots li.slick-active button{
    @apply w-3 h-3;
}



.students .ant-carousel .slick-dots{
    @apply bottom-0;
}
.students .ant-carousel .slick-dots li button{
    @apply bg-slate-600;
}


.faqs .ant-collapse .ant-collapse-item{
    @apply bg-indigo-700 rounded-lg mt-5 rounded-t-lg;
}
.faqs .ant-collapse .ant-collapse-item .ant-collapse-header{
    @apply md:p-5;
}
.faqs .ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{
    @apply md:pb-2;
}
.faqs .ant-collapse .ant-collapse-item .ant-collapse-header-text{
    @apply text-xl md:text-2xl font-semibold text-gray-50 ;
}
.faqs .ant-collapse .ant-collapse-item .ant-collapse-content-box{
    @apply text-lg md:text-xl font-normal text-gray-50 md:p-5 md:pt-3;
}